import axios from "axios";
import { useEffect, useState } from "react";
import { url } from "../values/url";
import { useCookies } from "react-cookie";

export function AddAccount(props) {
  const [id, setid] = useState("");
  const [name, setname] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["admin"])

  const inputChange = (event) => {
    props.search(event.target.value);
    setname(event.target.value);
  };

  const requestid = async () => {
    try {
      const resp = await axios.post(`${url}auth/requestid`, {
        admin: cookies.admin,
      });
      setid(resp.data.id);
    } catch (e) {
      setid("Error");
    }
  };

  const createAccount = async () => {
    try {
      let resp = await axios.post(`${url}auth/create`, {
        admin: cookies.admin,
        userid: id,
        name: name,
      });
      console.log(resp);

      if (resp.status == 200) props.refresh();
      requestid();
      setname("");
      props.search("")
    } catch (e) {
      setid("Error");
    }
  };

  useEffect(() => {
    requestid();
  }, []);

  return (
    <div className="addform">
      <input
        type="text"
        className="add"
        placeholder="Username"
        onChange={inputChange}
        value={name}
      />
      <span
        style={{
          fontSize: 15,
        }}
      >
        id: {id}
      </span>
      <input
        type="button"
        value="Add Account"
        className="submit"
        onClick={createAccount}
      />
    </div>
  );
}
