export const COLOR = {
  black: "#FF000000",
  white: "#FFFFFFFF",
  red: "#C33",
  green: "#25C196",
  blue: "#7289da",
  lightgrey: "#99aab5",
  grey: "#424549",
  semidark: "#36393e",
  dark: "#282b30",
  jet: "#1e2124",
};
