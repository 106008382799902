import { useCookies } from "react-cookie";

export function SidebarElement(props) {
  const [cookies, setCookie, removeCookie] = useCookies(["admin"])

  const routeChange = () => {
    if (props.path == "logout") {
      removeCookie("admin")
      window.location.href = "/login"
    }

    window.location.href = props.path
  };

  return (
    <div className="sbElement" onClick={routeChange}>
      <div className="icon">{props.icon}</div>
      <p>{props.name}</p>
    </div>
  );
}
