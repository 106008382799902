import { useState } from "react";
import "./Login.css";
import axios from "axios";
import { url } from "../values/url";
import { useCookies } from "react-cookie";

export function Login() {
  const [pass, setpass] = useState("");
  const [error, seterror] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["admin"])

  const handlePass = (event) => {
    setpass(event.target.value);
  };

  const login = async (e) => {
    e.preventDefault()

    try {
      if (!pass) {
        seterror("Enter password")
        return
      }

      let resp = await axios.post(`${url}auth/admin`, {
        admin: pass,
      });

      if (resp.status == 200) {
        setCookie("admin", pass)
        window.location.href = "/"
      };

    } catch (e) {
      if (e.response.status == 401) {
        seterror("Invalid Credentials")
      } else {
        seterror("Internal Server Error. Contact admin")
      }
      console.log(e.response)
    }
  };

  return (
    <div className="logincontainer">
      <form className="login" onSubmit={login}>
        <p>Ekoaire Admin</p>
        <p className="error">{error}</p>
        <input
          placeholder="Enter Password"
          type="password"
          value={pass}
          className="pass"
          onChange={handlePass}
          autoFocus
        />
        <input type="button" value="Login" className="submit" onClick={login} />
      </form>
    </div>
  );
}
