export function Remarks(props) {
  props.data.sort(function (a, b) {
    return new Date(a).getTime() - new Date(b).getTime();
  });
  const remObj = props.data.map((d) => (
    <div className="remark" key={d.timestamp}>
      <span>{namereduce(d.name)} : </span><br />
      {d.remark}
      <span> at {formatAMPM(new Date(d.timestamp))}</span>
    </div>
  ));

  return <div className="remarkcontainer">{remObj}</div>;
}

const namereduce = (name) => {
  let names = name.split(" ");
  if (names.length > 1 && name[1].length > 0) {
    return `${names[0]} ${names[1][0]}.`;
  } else {
    return name;
  }
};

function formatAMPM(date) {
  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
