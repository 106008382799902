import { useSearchParams } from "react-router-dom";
import "./Info.css";
import { Punch } from "./Punch";
import { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../values/url";
import { useCookies } from "react-cookie";

export function Info() {
  const [searchParams] = useSearchParams();
  const [date, setdate] = useState(searchParams.get("date"));
  const [name, setname] = useState("");
  const [total, settotal] = useState("-");
  const [totaltime, settotaltime] = useState("-");
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [punches, setPunches] = useState([]);

  const [cookies, setCookie, removeCookie] = useCookies(["admin"]);

  const dateChange = (event) => {
    setdate(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let resp = await axios.post(`${url}info/dateduser`, {
          admin: cookies.admin,
          date: date,
          userid: searchParams.get("userid"),
        });

        let obj = resp.data.data[0];

        if (obj.globalStartTime) {
          if (obj.totalDistance < 1000) {
            settotal(`${obj.totalDistance.toFixed(0)} m`);
          } else {
            settotal(`${(obj.totalDistance / 1000).toFixed(2)} km`);
          }
        }
        setname(obj.user);
        setstart(formatAMPM(obj.globalStartTime));
        setend(formatAMPM(obj.globalEndTime));
        setPunches(obj.punches);
        settotaltime(calculateTotalTime(obj.punches));
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
    return () => {};
  }, [date]);

  return (
    <div className="infocontainer">
      <div className="punchinfo">
        <div className="usertitle">
          <div className="uname">{namereduce(name)}</div>
          <input
            type="date"
            className="date"
            value={date}
            onChange={dateChange}
          />
        </div>
        <p>
          <b>Total: ({total})</b> {start} to {end} <span className="red">({totaltime})</span>
        </p>
        <div className="punchcontainer">
          {punches.map((e) => (
            <Punch data={e} key={e.startTime}/>
          ))}
        </div>
      </div>
      <div className="maprem">
        <div>Map placeholder</div>
        <div>Remark placeholder</div>
      </div>
    </div>
  );
}

const namereduce = (d) => {
  let name = d.toLowerCase();
  let names = name.split(" ");
  if (names.length > 1 && name[1].length > 0) {
    return `${names[0]} ${names[1][0]}.`;
  } else {
    return name;
  }
};

function formatAMPM(date) {
  var hours = new Date(date).getUTCHours();
  var minutes = new Date(date).getUTCMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function calculateTotalTime(punches) {
  let totalTime = 0; // in milliseconds

  punches.forEach((punch) => {
    const startTime = new Date(punch.startTime);
    const endTime = new Date(punch.endTime);

    // Calculate the time difference in milliseconds
    const timeDifference = endTime - startTime;

    // Add the time difference to the total time
    totalTime += timeDifference;
  });

  // Convert the total time to a human-readable format (optional)
  const totalHours = Math.floor(totalTime / (1000 * 60 * 60));
  const totalMinutes = Math.floor((totalTime % (1000 * 60 * 60)) / (1000 * 60));

  if (totalHours == 0) {
    return `${totalMinutes} min`;
  } else {
    return `${totalHours}h ${totalMinutes} min`;
  }
}
