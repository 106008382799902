import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR } from "../values/colors";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export function UserSummary(props) {
  let startTime = new Date(props.user.globalStartTime);
  let endTime = new Date(props.user.globalEndTime);

  startTime = formatAMPM(startTime);
  endTime = formatAMPM(endTime);

  let dist = "-";
  if (props.user.globalStartTime) {
    if (props.user.totalDistance < 1000) {
      dist = `${props.user.totalDistance.toFixed(0)} m`;
    } else {
      dist = `${(props.user.totalDistance / 1000).toFixed(2)} km`;
    }
  }

  const gotoInfo = () => {
    window.location.href = `/info?date=${props.date}&userid=${props.user.userId}`
  }

  return (
    <div
      className="usersummary"
      onClick={
        gotoInfo
      }
    >
      <p
        style={{
          backgroundColor: props.user.globalStartTime ? COLOR.green : COLOR.red,
        }}
      >
        {props.user.globalStartTime ? "P" : "A"}
      </p>
      <p>{props.user.userName}</p>
      <p>{props.user.globalStartTime ? startTime : "-"}</p>
      <FontAwesomeIcon icon={faArrowRight} className="rightarrow" />
      <p>{props.user.globalEndTime ? endTime : "-"}</p>
      <p>{dist}</p>
    </div>
  );
}

function formatAMPM(date) {
  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
