import { useEffect, useState } from "react";
import "./Home.css";
import { UserSummary } from "./UserSummary";
import axios from "axios";
import { url } from "../values/url";
import { Total } from "./Total";
import { useCookies } from "react-cookie";
import { Remarks } from "./Remarks";

export function Home() {
  let dateString = new Date().toISOString().substring(0, 10);
  const [date, setdate] = useState(dateString);
  const [users, setusers] = useState([]);
  const [remarks, setremarks] = useState([]);

  const [cookies, setCookie, removeCookie] = useCookies(["admin"])

  const dateChange = (event) => {
    setdate(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let resp = await axios.post(`${url}info/summary`, {
          admin: cookies.admin,
          date: date,
        });

        setusers(resp.data.data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
    return () => {};
  }, [date]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let resp = await axios.post(`${url}info/datedremarks`, {
          admin: cookies.admin,
          date: date,
        });

        setremarks(resp.data.data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
    return () => {};
  }, [date]);

  return (
    <div className="home">
      <div className="summary">
        <div className="datepicker">
          <span>Date:</span>
          <input
            type="date"
            className="date"
            value={date}
            onChange={dateChange}
          />
        </div>
        <Total
          present={users.filter((x) => x.globalStartTime != null).length}
          absent={users.filter((x) => x.globalStartTime == null).length}
        />
        <Remarks data={remarks}/>
      </div>
      <div className="usercontainer">
        {users.map((user) => (
          <UserSummary key={user.userId} user={user} date={date} />
        ))}
      </div>
    </div>
  );
}
