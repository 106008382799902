import { useCookies } from "react-cookie"

export const Protected = ({children}) => {
    const [cookies, setCookie, removeCookie] = useCookies(["admin"])      
    
    if (!cookies.admin) {
        window.location.href = "/login"
        return <div>No access</div>
    } else {
        return children
    }
}