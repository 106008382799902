import { AddAccount } from "./AddAccount";
import "./Accounts.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { AccountElement } from "./AccountElement";
import { ConfirmProvider } from "material-ui-confirm";
import { url } from "../values/url";

export function Accounts() {
  const [error, seterror] = useState("");
  const [accounts, setaccounts] = useState([]);
  const [query, setquery] = useState("");
  const [refresh, setrefresh] = useState(true);

  const refreshState = () => {
    setrefresh(!refresh);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let resp = await axios.post(`${url}auth/accounts`, {
          admin: "airtel123",
        });

        setaccounts(resp.data.users);
      } catch (e) {
        console.log(e);
        if (e.response.status == 401) seterror("Unauthorized");
      }
    };

    fetchData();

    return () => {};
  }, [refresh]);

  return (
    <div className="accounts">
      <AddAccount search={setquery} refresh={refreshState} />
      {error}
      {/* <AccountElement name="Addi" id="914521" />
      <AccountElement name="Adergergdi" id="914521" />
      <AccountElement name="Ad" id="914521" />
      <AccountElement name="Addierbre" id="914521" />
      <AccountElement name="Addi" id="914521" />
      <AccountElement name="Adergergdi" id="914521" />
      <AccountElement name="Ad" id="914521" />
      <AccountElement name="Addierbre" id="914521" />
      <AccountElement name="Addi" id="914521" />
      <AccountElement name="Adergergdi" id="914521" />
      <AccountElement name="Ad" id="914521" />
      <AccountElement name="Addierbre" id="914521" />
      <AccountElement name="Addi" id="914521" />
      <AccountElement name="Adergergdi" id="914521" />
      <AccountElement name="Ad" id="914521" />
      <AccountElement name="Addierbre" id="914521" /> */}
      
      <ConfirmProvider>
        {accounts
          .filter((x) => x.name.toLowerCase().includes(query.toLowerCase()))
          .map((acc) => (
            <AccountElement
              key={acc.userid}
              name={acc.name}
              id={acc.userid}
              refresh={refreshState}
            />
          ))}
      </ConfirmProvider>
    </div>
  );
}
