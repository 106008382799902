import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import { url } from "../values/url";
import { useCookies } from "react-cookie";

export function AccountElement(props) {
  const confirm = useConfirm();
  const [cookies, setCookie, removeCookie] = useCookies(["admin"])

  const handleDelete = () => {
    confirm({
      description:
        "This will delete all data associated with the account. Are you sure you wish to proceed?",
    })
      .then(async () => {
        try {
          let resp = await axios.post(`${url}auth/delete`, {
            admin: cookies.admin,
            userid: props.id
          });

          props.refresh()
        } catch (e) {
          console.log(e);
        }
      })
      .catch();
  };

  return (
    <div className="acc">
      <p>{props.name}</p>
      <p>id: {props.id}</p>
      <p onClick={handleDelete}>Delete</p>
    </div>
  );
}
