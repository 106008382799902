import { useState } from "react";
import "./Sidebar.css";
import { SidebarElement } from "./SidebarElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackspace,
  faBars,
  faCheese,
  faCircleMinus,
  faHamburger,
  faHome,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

export function Sidebar() {
  const [sidebar, setSidebar] = useState(window.innerWidth > 800 ? true : false);

  const flipSidebar = () => {
    setSidebar(!sidebar);
  };

  return (
    <div>
      {/* Hamberger menu button */}
      <button className="hamburger icon" onClick={flipSidebar}>
        <FontAwesomeIcon icon={faBars} />
      </button>

      {/* Hyperlinks */}
      <div
        className="sidebar"
        style={{
          transform: sidebar ? "translate(0)" : "translate(-100%)",
          width: sidebar ? "200px" : "0px",
          padding: sidebar ? "0 20px" : "0px",
          transition: "all .2s",
        }}
      >
        <div className="padtop" />

        <SidebarElement
          name="Home"
          path="/"
          icon={<FontAwesomeIcon icon={faHome} />}
        />
        <SidebarElement
          name="Accounts"
          path="accounts"
          icon={<FontAwesomeIcon icon={faLock} />}
        />
        <SidebarElement
          name="Logout"
          path="logout"
          icon={<FontAwesomeIcon icon={faBackspace} />}
        />
      </div>
    </div>
  );
}
