import logo from "./logo.svg";
import "./App.css";
import { Sidebar } from "./components/sidebar/Sidebar";
import { Content } from "./components/Content";
import { Login } from "./components/auth/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Protected } from "./components/protected";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route
              path="/*"
              element={
                <div className="mainholder">
                  <Protected>
                    <Sidebar />
                    <Content />
                  </Protected>
                </div>
              }
            />
            <Route index path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
