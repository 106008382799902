import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./Content.css";
import { Accounts } from "./accounts/Accounts";
import { Home } from "./home/Home";
import { Info } from "./information/Info";

export function Content() {
  return (
    <div className="main-content">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/info" element={<Info />} />
        <Route
          path="/*"
          element={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              404 Not found
            </div>
          }
        />
      </Routes>
    </div>
  );
}
