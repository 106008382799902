export function Punch(props) {
  let data = props.data

  let dist = "-"
  if (data.startTime && data.distance) {
    if (data.distance < 1000) {
      dist = `${data.distance.toFixed(0)} m`;
    } else {
      dist = `${(data.distance / 1000).toFixed(2)} km`;
    }
  }

  return (
    <div className="punch">
      <div className="punchhead">
        <p>{formatAMPM(data.startTime)} to {formatAMPM(data.endTime)}</p>
        <p>{dist??0}</p>
      </div>
      <div className="locations">
        {data.points.length != 0? `Placeholder location info: ${data.points}`: "No data found. Unrecorded"}
      </div>
    </div>
  );
}


function formatAMPM(date) {
  var hours = new Date(date).getUTCHours();
  var minutes = new Date(date).getUTCMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
