import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { COLOR } from "../values/colors";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.color = COLOR.lightgrey;

export function Total(props) {
  const stats = {
    labels: ["Present", "Absent"],
    datasets: [
      {
        label: "# of people",
        data: [props.present, props.absent],
        backgroundColor: [COLOR.green, COLOR.red],
        borderColor: [COLOR.jet, COLOR.jet],
        borderWidth: 2,
        hoverOffset: 15,
      },
    ],
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const {ctx, data} = chart;

      ctx.save();
      ctx.font = 'bolder 1em sans-serif';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(` ${data.datasets[0].data[0]}/${data.datasets[0].data[0] + data.datasets[0].data[1]}`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);
    }
  }

  return (
    <div className="donut">
      <Doughnut name="attend-count" data={stats} plugins={[textCenter]}/>
    </div>
  );
}
